// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"b78752b25e2fc007f8cc9a87c19b0e5a6f17e6a8"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs';
import { getFriendlyEnvNameFromDomain, getReleaseString, getSentrySampleRate, isSentryEnabled, sentryDsn } from '@/utils/sentry';
import Logger from '@/utils/logger';

const sampleRate = getSentrySampleRate('client');

const clientSideErrorsToIgnore = [
  // The following error originates in the Google IMA plugin on some older versions of Chrome (particularly on ChromeOS),
  // and there doesn't seem to be anything we can do about it, so let's ignore it.
  /.+querySelectorAll.+div:has\(> iframe[id='[\d]+']\).+is not a valid selector/,
];

if (isSentryEnabled() && sampleRate > 0) {
  const logger = new Logger({ caller: 'sentry.client.config' });

  Sentry.init({
    dsn: sentryDsn,

    environment: `client-${getFriendlyEnvNameFromDomain(window.location.hostname)}`,
    release: getReleaseString(),

    // This value controls the percentage of error events that are sent to Sentry.
    sampleRate,

    // This value controls the percentage of transactions (full stack traces) that are sent to Sentry.
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: sampleRate,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    // These are a bit strict in order to avoid problems like
    // [this](https://github.com/getsentry/sentry-javascript/issues/5868), which we previously saw.
    tracePropagationTargets: [
      /^https?:\/\/localhost:/,
      /^https:\/\/pbskids\.org\//,
      /^https:\/\/stage\.pbskids\.org\//,

      // Prod and preprod
      /^https:\/\/([a-z\d\-]+)\.(pre)?prod\.pbskids\.org\//,

      // Amplify preprod and prod domains
      /^https:\/\/([^\/\.]+).(d2iktlrmh9f3mc|dkhcis20jaz0c)\.amplifyapp\.com\//,
    ],

    // Set the max message length to the max accepted by Sentry
    // https://develop.sentry.dev/sdk/data-handling/#variable-size
    maxValueLength: 8192,

    beforeSend: (event) => {
      // Let's ignore some known client-side errors that we can't do anything about.
      for (const errorToIgnore of clientSideErrorsToIgnore) {
        for (const exception of event.exception?.values ?? []) {
          if (exception.value && errorToIgnore.test(exception.value)) {
            logger.info('Not sending known error to Sentry: ', exception.value);
            return null;
          }
        }
      }
      return event;
    },
  });
}
